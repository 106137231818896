<template>
  <div class="form-inline d-inline">
    <div v-if="employee.loading" class="text-center form-inline d-inline">
      <b-spinner></b-spinner>
      <span>Loading...</span>
    </div>
    <b-badge
      v-else
      v-for="(item, index) in regionEmployeeNameGetters"
      :key="`cities_${index}`"
      variant="info"
      class="mr-1 text-with"

    >
      {{ item.name }}
    </b-badge>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "CitiesAreaCoverage",
  computed: {
    ...mapState({
      employee: state => state.employee.data
    }),
    ...mapGetters("employee", ["regionEmployeeNameGetters"])
  }
};
</script>
