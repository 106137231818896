<template>
  <div>
    <!-- tempat untuk menampilkan pesan error -->
    <div>
      <b-alert
        v-for="(item, index) in agendaSurat.errors"
        :key="`notif_error_${index}`"
        dismissible
        show
        variant="danger"
      >
        <span class="alert-icon"><i class="ni ni-bell-55"></i></span>
        <span class="alert-text">{{ item.message }}</span>
      </b-alert>
    </div>

    <form
      id="formCreateAgendaSurat"
      class="form pb-2"
      @submit.prevent="submitData"
    >
      <div
        v-if="['edit', 'upload'].includes(agendaSurat.typeAction)"
        class="form-group text-center"
      >
        <label for="name_user">Nomor Agenda</label>
        <h3>{{ agendaSurat.formData.agenda_number }}</h3>
      </div>
      <div class="form-group">
        <label for="name_user">Tujuan</label>
        <el-autocomplete
          id="name_user"
          :disabled="checkIsUpload"
          class="inline-input w-100"
          v-model="agendaSurat.formData.purpose"
          :fetch-suggestions="querySearch"
          placeholder="Isi Tentang Tujuan Agenda Surat..."
          :maxlength="100"
        ></el-autocomplete>
        <small>{{ inputPurposeLengthComputed }}/100</small>
      </div>

      <div class="form-group">
        <label for="subject">Perihal</label>
        <input
          id="subject"
          v-model="agendaSurat.formData.subject"
          class="form-control"
          placeholder="Isi Tentang Perihal Agenda Surat..."
          type="text"
          :disabled="checkIsUpload"
          maxlength="240"
        />
        <small>{{ inputSubjectLengthComputed }}/240</small>
      </div>

      <div class="form-group">
        <label for="date">Tanggal</label>
        <input
          id="date"
          v-model="agendaSurat.formData.date"
          class="form-control"
          placeholder="Isi data..."
          readonly
          type="email"
        />
      </div>

      <button
        :disabled="agendaSurat.loading"
        v-if="!checkIsUpload"
        class="btn btn-primary pl-2 pr-2 float-right btn-sm"
        type="submit"
      >
        {{ agendaSurat.formData.id ? "Update" : "Simpan" }} Data
      </button>
    </form>

    <div v-if="checkIsUpload" class="form-group mb-3">
      <label for="petugas">PETUGAS</label>
      <input
        id="petugas"
        v-model="agendaSurat.formData.officer"
        class="form-control"
        placeholder="Isi data..."
        readonly
        required
        type="text"
      />
    </div>

    <div
      v-if="
        checkFileInData && agendaSurat.typeAction === 'upload' && !uploadFile
      "
      class="d-flex"
    >
      <a :href="urlFileInData" target="_blank" class="text-primary">
        <h4 class="text-primary">LIHAT BERKAS</h4>
      </a>
      ____
      <h4 @click="() => (uploadFile = true)" class="cursor-pointer">
        UBAH BERKAS?
      </h4>
    </div>
    <div v-else>
      <label v-if="checkIsUpload" for="file">Pilih Berkas yang diupload</label>
      <div v-if="checkIsUpload" class="form-inline w-100">
        <div class="form-group">
          <input
            id="file"
            class="form-control mr-3"
            placeholder="Isi data..."
            required
            type="file"
            @change="handleFileChange"
            accept=".pdf"
          />
        </div>
        <div v-if="!sharedSchool" class="form-group">
          <button
            :disabled="checkFileIsChoose"
            v-b-tooltip.hover
            class="btn btn-dark text-white"
            :class="{
              'cursor-nodrop': checkFileIsChoose
            }"
            title="Proses Upload Berkas"
            @click="onUploadFileEventHandler"
          >
            <i class="fas fa-upload"></i>
          </button>
        </div>
      </div>
      <small v-if="checkIsUpload"
        >Upload Berkas hanya bisa dilakukan oleh PETUGAS yang membuat
        agenda.</small
      >
    </div>

    <div v-if="agendaSurat.typeAction === 'upload'">
      <div v-if="!checkSchoolInData || changeSharedSchool" class="form-check mt-2">
        <input
          type="checkbox"
          :checked="sharedSchool"
          @change="() => (sharedSchool = !sharedSchool)"
          class="form-check-input"
          id="checkSchoolShare"
        />
        <label
          class="form-check-label font-size-13 text-dark"
          for="checkSchoolShare"
        >
          Surat dikirim ke Sekolah?
        </label>
      </div>
      <div v-else class="d-flex">
        <h4>
          Dikirim Ke <span class="text-primary">{{ schoolNameInDetailFormData }}</span>
        </h4>
        ____
        <h4 @click="() => (changeSharedSchool = true)" class="cursor-pointer">
          UBAH TUJUAN?
        </h4>
      </div>

      <div class="mt-2" v-if="sharedSchool">
        <el-select
          v-model="agendaSurat.formData.schoolUuid"
          aria-autocomplete="none"
          class="w-50"
          filterable
          placeholder="Pilih Sekolah"
        >
          <el-option
            v-for="(item, index) in schoolsItemsAll"
            :key="`listschoolall_${index}`"
            :label="item.name"
            :value="item.row_id"
          >
          </el-option>
        </el-select>
      </div>

      <div v-if="sharedSchool" class="form-group mt-2">
        <button
          :disabled="checkFileIsChoose && checkFormDataSchoolUuid"
          v-b-tooltip.hover
          class="btn btn-primary text-white"
          :class="{
            'cursor-nodrop': checkFileIsChoose
          }"
          title="Proses Data"
          @click="onUploadFileEventHandler"
        >
          Proses Data
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Swal from "sweetalert2";
import { ShowLoadingSwal } from "@/utils/Api";
import { Message } from "element-ui";

export default {
  name: "DetailEmployee",
  props: ["onClose", "jenisModal"],
  data() {
    return {
      changeSharedSchool: false,
      sharedSchool: false,
      uploadFile: true
    };
  },
  computed: {
    ...mapState({
      schoolsItemsAll: state => state.schools.data.itemsAll,
      agendaSurat: state => state.agendaSurat.data
    }),

    schoolNameInDetailFormData() {
      return this.agendaSurat.detailFormData.school?.name || "Tidak ditemukan!";
    },

    inputPurposeLengthComputed() {
      const a = this.agendaSurat.formData.purpose;
      return a ? a.length : 0;
    },

    inputSubjectLengthComputed() {
      const a = this.agendaSurat.formData.subject;
      return a ? a.length : 0;
    },

    checkSchoolInData() {
      return !!this.agendaSurat.detailFormData.school;
    },

    checkFileInData() {
      return !!this.agendaSurat.detailFormData.file;
    },

    urlFileInData() {
      return this.agendaSurat.detailFormData?.file || "";
    },

    checkIsUpload() {
      return this.jenisModal === "upload";
    },

    checkFileIsChoose() {
      return !this.agendaSurat.formData.file;
    },

    checkFormDataSchoolUuid() {
      return !this.agendaSurat.formData.schoolUuid;
    }
  },
  mounted() {
    this.$store.commit("agendaSurat/changeAgendaSurat", { errors: [] });
    if (this.checkFileInData) {
      this.uploadFile = false;
    }
  },
  methods: {
    ...mapActions("agendaSurat", [
      "putAgendaSurat",
      "postAgendaSurat",
      "postFileAgendaSurat",
      "actionEdit",
      "actionRead",
      "actionCreate",
      "actionDelete"
    ]),
    ...mapMutations("agendaSurat", ["changeFormDataAgendaSuratObjectAssign"]),

    async onUploadFileEventHandler() {
      if (this.sharedSchool && !this.agendaSurat.formData.schoolUuid) {
        Message({
          type: "error",
          message:
            "Sekolah belum dipilih, Anda tedeteksi ingin Agenda Surat dikirim ke sekolah."
        });
        return false;
      }

      if (
        !this.agendaSurat.detailFormData.file &&
        !this.agendaSurat.formData.schoolUuid &&
        !this.agendaSurat.formData.file
      ) {
        return false;
      }

      if (!this.agendaSurat.detailFormData.file) {
        if (!this.agendaSurat.formData.file) {
          Message({
            type: "error",
            message: "Berkas Belum dipilih"
          });
          return false;
        }
      }
      ShowLoadingSwal();
      await this.postFileAgendaSurat();
      this.$emit("closeModal");
    },

    handleFileChange(event) {
      try {
        const file = event.target.files[0]; // Get the first file from the file input
        this.changeFormDataAgendaSuratObjectAssign({
          file: file
        });
      } catch (e) {
        console.log("terjadi kesalahan", e);
      }
    },
    querySearch(queryString, cb) {
      let links = this.agendaSurat.itemsPurposeAll.map(item => {
        return {
          value: item
        };
      });
      let results = queryString
        ? links.filter(this.createFilter(queryString))
        : links;
      cb(results);
    },
    createFilter(queryString) {
      const teks = queryString ? queryString.toLowerCase() : "";
      return link => {
        if (link.value) {
          return link.value.toLowerCase().includes(teks);
        }
        return false;
      };
    },
    validasiFormData() {
      const { purpose, subject } = this.agendaSurat.formData;
      if (!purpose || !subject) {
        this.$message("Isian Pada Form harus diisi semua", "info");
        return false;
      }
      return true;
    },

    async submitData() {
      if (this.agendaSurat.loading) {
        console.log("call enpoint duplicate: STOP");
        return false;
      }
      ShowLoadingSwal();

      let result = false;
      if (this.agendaSurat.typeAction === "edit") {
        result = await this.putAgendaSurat();
      } else {
        result = await this.postAgendaSurat();
      }
      Swal.close();
      if (result) {
        this.$emit("closeModal");
      }
    }
  },
  beforeDestroy() {
    this.actionRead();
  },
  watch: {
    "agendaSurat.formData.name": function(newValue) {
      if (!newValue) {
        return;
      }
      // Menghilangkan karakter non-alfanumerik dan spasi, lalu mengubah ke huruf kecil
      const formatResult = newValue
        .replace(/[^a-z0-9]/gi, "") // Menghilangkan karakter non-alfanumerik
        .replace(/\s/g, "") // Menghilangkan spasi
        .toLowerCase(); // Mengubah ke huruf kecil

      this.changeFormDataEmployeeObjectAssign({
        email: formatResult + "@diskola.id"
      });
    }
  }
};
</script>
