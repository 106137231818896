<template>
  <div class="form-inline d-inline">
    <div v-if="agendaSurat.loading" class="text-center form-inline d-inline">
      <b-spinner></b-spinner>
      <span>Loading...</span>
    </div>
    <b-badge
      v-else
      variant="info"
      class="mr-1 text-with"

    >
      {{ cabangAdminGetters }}
    </b-badge>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "CabangCities",
  computed: {
    ...mapState({
      agendaSurat: state => state.agendaSurat.data
    }),
    ...mapGetters(["cabangAdminGetters"])
  }
};
</script>
