<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8" type="gradient-success">
      <div class="notification-refresh border p-2 mb-4">
        <div class="row">
          <div class="col-md-8 col d-flex align-items-center">
            <div class="logo-notification">
              <i class="fas fa-street-view"></i>
              Sekolah
            </div>
          </div>
          <div class="col-md-4 col d-flex align-items-center">
            <div class="information-notification ml-auto">
              <b-button
                v-b-tooltip.hover
                class="btn-sync ml-1 bg-success"
                size="sm"
                title="Refresh Data"
                @click="getSchoolsNaunganPengawas"
              >
                <i class="fas fa-sync-alt"></i>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-12">
          <div class="header-information d-flex align-items-center mb-3">
            <div class="header-info">
              <h1></h1>
            </div>
          </div>
        </div>
      </div>
      <!-- /.row -->

      <div class="row">
        <!--<div class="col-12">
          <MenuTabs menu-active="SEKOLAH" />
        </div>-->

        <div class="col-12">
          <div class="card">
            <!-- /.card-header -->
            <div class="card-body">
              <TableData />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";

export default {
  name: "PengawasNaunganPage",
  data() {
    return {
      government_id: null
    };
  },
  computed: {},
  components: {
    TableData: () => import("./Table")
  },
  methods: {
    ...mapActions("pengawasNaungan", ["getSchoolsNaunganPengawas"]),
  },
  beforeMount() {},
  mounted() {}
};
</script>
<style lang="scss">
@import "@/assets/scss/custom/generate-token.scss";
</style>